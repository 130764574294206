import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

import useWindowSize from '@/hooks/useWindowSize'

import './Footer.scss'

const cnFooter = cn('Footer')

export const Footer: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const { lessDesktop } = useWindowSize()

  return (
    <footer className={cnFooter()}>
      <div className={cnFooter('line')}>
        {!lessDesktop && (
          <div className={cnFooter('left')}>
            <div className={cnFooter('text')}>
              {t('All rights reserved by Asortio LLC')}
            </div>
          </div>
        )}
        <div className={cnFooter('center')}>
          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Company')}:</div>

            <a
              className={cnFooter('link')}
              href='https://asortio.com/#about'
              target='_blank'
            >
              {t('About us')}
            </a>

            {/* <a className={cnFooter('link')}> {t('Careers')}</a> */}
          </div>
          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Legal')}:</div>

            <a
              className={cnFooter('link')}
              href='https://asortio.com/terms-and-conditions.html'
              target='_blank'
            >
              {t('Terms and conditions')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://asortio.com/privacy-policy.html'
              target='_blank'
            >
              {t('Privacy policy')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://asortio.com/cookies-policy.html'
              target='_blank'
            >
              {t('Coolies Policy')}
            </a>
          </div>

          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Information')}:</div>

            <a
              className={cnFooter('link')}
              href='https://asortio.com/#faq'
              target='_blank'
            >
              {t('Questions')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://asortio.com/#contact'
              target='_blank'
            >
              {t('Contacts')}
            </a>
          </div>
        </div>

        <div className={cnFooter('right')}>
          {lessDesktop && (
            <div className={cnFooter('text', { bottom: true })}>
              {t('All rights reserved by Asortio LLC')}
            </div>
          )}
          <div className={cnFooter('text')}>
            {t(
              'Asortio.com is operated by Asortio LLC (Reg. No. 7252332478). Registered address UL. PIOTRKOWSKA 116 / 52, 90-006 ŁÓDŹ',
            )}
          </div>
        </div>
      </div>
    </footer>
  )
})
